<template>
	<v-card flat v-if="initialized">
		<v-card-text>
			<v-row>
				<v-col cols="12" sm="6" md="6" lg="4" xl="3">
					<v-card>
						<v-card-title> Site Ayarları </v-card-title>
						<v-card-text>
							<v-row>
								<v-col cols="12" md="12" class="d-flex align-center">
									<v-checkbox
										class="pt-0 mt-0"
										v-model="cmsSiteSettings.isRedirectCustomerSite"
										label="Müşteri web sitesine yönlendirme"
										hide-details="auto"
										@change="redirectCustomerSiteChanged"
									></v-checkbox>
								</v-col>
								<v-col cols="12" md="12" v-if="isRedirectUrlShow">
									<v-text-field
										label="Web sitesi adresi.."
										outlined
										hide-details="auto"
										flat
										dense
										color="teal"
										v-model="$v.cmsSiteSettings.redirectSiteUrl.$model"
										:error-messages="redirectSiteUrlErrors()"
									></v-text-field>
								</v-col>
								<v-col cols="12">
									<v-checkbox
										class="pt-0 mt-0"
										v-model="cmsSiteSettings.isExpertiseFileMandatory"
										label="Ekspertiz dosyaları zorunlu"
										hide-details="auto"
									></v-checkbox>
								</v-col>
								<v-col cols="12">
									<v-checkbox
										class="pt-0 mt-0"
										v-model="cmsSiteSettings.isLocation"
										label="Çoklu Şube Randevusu Aktif"
										hide-details="auto"
									></v-checkbox>
								</v-col>
								<v-col cols="12" v-if="cmsSiteSettings.isLocation" class="ml-5">
									<v-radio-group
										v-model="cmsSiteSettings.scheduleType"
										row
										mandatory
										class="pt-0 mt-0"
										hide-details="auto"
									>
										<v-radio label="Fiyatlamada" :value="1"></v-radio>
										<v-radio label="İletişimde" :value="2"></v-radio>
									</v-radio-group>
								</v-col>
								<v-col cols="12">
									<v-checkbox
										class="pt-0 mt-0"
										v-model="cmsSiteSettings.hasUI"
										label="Arayüz (UI) Kullanımı Aktif"
										hide-details="auto"
									></v-checkbox>
								</v-col>
								<v-col cols="12">
									<v-checkbox
										class="pt-0 mt-0"
										v-model="cmsSiteSettings.isGivePrice"
										label="Araç Değerleme Aktif"
										hide-details="auto"
									></v-checkbox>
								</v-col>
								<v-col cols="12">
									<v-text-field
										label="İletişim Formu E-Mail"
										outlined
										hide-details="auto"
										flat
										dense
										color="teal"
										v-model="$v.cmsSiteSettings.contactFormEMail.$model"
										@change="contactFormEMail"
										:error-messages="contactFormEMail()"
									></v-text-field>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12" sm="6" md="6" lg="4" xl="3" v-if="hasListing">
					<v-card>
						<v-card-title> Listing Ayarları </v-card-title>
						<v-card-text>
							<v-row>
								<v-col cols="12">
									<v-checkbox
										class="pt-0 mt-0"
										v-model="cmsSiteSettings.hasListingCount"
										label="Filtrede araç sayılarını göster (Listing)"
										hide-details="auto"
									></v-checkbox>
								</v-col>
								<v-col cols="12">
									<v-checkbox
										class="pt-0 mt-0"
										v-model="cmsSiteSettings.showListingPlate"
										label="Araç Plakalarını göster (Listing)"
										hide-details="auto"
									></v-checkbox>
								</v-col>
								<v-col cols="12">
									<v-checkbox
										class="pt-0 mt-0"
										v-model="cmsSiteSettings.showListingCarMenu"
										label="Ana menüde Araçlar bağlantısı göster (Listing)"
										hide-details="auto"
									></v-checkbox>
								</v-col>
								<v-col cols="12">
									<v-checkbox
										class="pt-0 mt-0"
										v-model="cmsSiteSettings.hasThumbImgZoom"
										label="Küçük resimleri yakınlaştır (Listing)"
										hide-details="auto"
									></v-checkbox>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12" sm="6" md="6" lg="4" xl="3" v-if="false">
					<v-card>
						<v-card-title>
							Site Haritası (Site Map)
							<v-spacer></v-spacer>

							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										fab
										x-small
										icon
										target="_blank"
										v-bind="attrs"
										v-on="on"
										href="https://developers.google.com/search/docs/crawling-indexing/sitemaps/overview?hl=tr"
									>
										<v-icon small color="primary">fa-question</v-icon>
									</v-btn>
								</template>
								<span>Site haritası nedir?</span>
							</v-tooltip>
						</v-card-title>
						<v-card-text>
							<div class="d-flex flex-column justify-space-around align-center justify-center">
								<div v-if="getSite.hasSiteMap" class="mb-2 d-flex flex-column align-center justify-center">
									<div class="d-flex align-center">
										<v-icon left small color="green">fa-globe</v-icon> Site haritanız oluşturulmuş.
									</div>
									<v-btn
										small
										text
										plain
										link
										:href="siteMapUrl"
										target="_blank"
										class="font-weight-bold text-lowercase"
									>
										{{ origin }}/sitemap.xml
									</v-btn>
								</div>
								<div class="font-weight-bold text-subtitle-1 red--text mb-2" v-else>Şuanda bir site haritanız yok.</div>
								<v-btn color="green" outlined class="text-capitalize" @click="generateSiteMapDialog"
									>Site Haritası{{ getSite.hasSiteMap ? 'nı yeniden' : '' }} Oluştur</v-btn
								>
							</div>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-card-text>
		<v-card-actions>
			<v-btn
				:loading="savePreloader"
				color="teal"
				class="text-capitalize"
				:dark="!$v.cmsSiteSettings.$invalid"
				@click="save"
				:disabled="$v.cmsSiteSettings.$invalid"
			>
				Değişiklikleri Kaydet
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import siteSettingValidationMixin from '@/mixins/validations/backoffice/siteSettingValidationMixin';
import { FETCH_SITE_SETTINGS, GENERATE_SITEMAP, UPDATE_SITE_SETTING } from '@/store/modules/company.module';
import { DISPOSE_MESSAGE, SET_MESSAGE_DIALOG } from '@/store/modules/messageDialog.module';
import { validationMixin } from 'vuelidate';
import { mapActions, mapGetters } from 'vuex';

export default {
	name: 'Site',
	mixins: [validationMixin, siteSettingValidationMixin],
	data() {
		return {
			savePreloader: false,
			cmsSiteSettings: {
				isRedirectCustomerSite: false,
				redirectSiteUrl: '',
				hasListingCount: false,
				contactFormEMail: '',
				isExpertiseFileMandatory: true,
				showListingPlate: false,
				showListingCarMenu: false,
				isLocation: false,
				hasUI: false,
				isGivePrice: false,
				hasThumbImgZoom: false,
				scheduleType: null,
			},
		};
	},

	computed: {
		...mapGetters(['settings', 'hasListing', 'getSite']),
		initialized() {
			return this.cmsSiteSettings !== null;
		},
		isRedirectUrlShow() {
			return this.cmsSiteSettings?.isRedirectCustomerSite;
		},
		siteMapUrl() {
			if (this.getSite.hasSiteMap) {
				return `${this.origin}/sitemap.xml`;
			}
			return '#';
		},
		origin() {
			return window.location.origin;
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.fetchSiteSettings();
		});
	},
	methods: {
		...mapActions({
			setMessageDialog: SET_MESSAGE_DIALOG,
			disposeMessageDialog: DISPOSE_MESSAGE,
			fetchSiteSettings: FETCH_SITE_SETTINGS,
			updateSiteSetting: UPDATE_SITE_SETTING,
			generateSiteMap: GENERATE_SITEMAP,
		}),
		redirectCustomerSiteChanged() {
			if (!this.cmsSiteSettings.isRedirectCustomerSite) this.cmsSiteSettings.redirectSiteUrl = '';
		},
		save() {
			this.savePreloader = true;
			this.updateSiteSetting(this.cmsSiteSettings)
				.then(() => {
					this.setMessageDialog({
						messageType: 'info',
						subTitle: 'Başarılı!',
						text: 'Değişiklikler başarılı bir şekilde kaydedilmiştir.',
					});
				})
				.finally(() => {
					this.savePreloader = false;
				});
		},
		generateSiteMapDialog() {
			this.setMessageDialog({
				messageType: 'warning',
				subTitle: 'Emin misiniz?',
				text: this.getSite.hasSiteMap
					? 'Şuanda bir site haritanız var. Site haritanızı yeniden oluşturmak istediğinizden emin misiniz?'
					: 'Henüz bir site haritanız yok. Oluşturmak istediğinizden emin misiniz?',
				closeButtonText: 'Vazgeç',
				buttons: [
					{
						text: 'Evet',
						color: 'teal',
						action: () => {
							this.generateSiteMap()
								.then(() => {
									this.disposeMessageDialog().then(() => {
										this.setMessageDialog({
											messageType: 'info',
											subTitle: 'Başarılı',
											text: `Site haritası başarılı bir şekilde oluşturulmuştur. <p>Aşağıda ki URL adresini gogle search console üzerinde kullanabilirsiniz. <p class="font-weight-bold">${this.origin}/sitemap.xml</p>`,
										});
									});
								})
								.catch(() => {
									this.disposeMessageDialog().then(() => {
										this.setMessageDialog({
											messageType: 'error',
											subTitle: 'Hata',
											text: 'Site haritası oluşturulurken beklenmeyen bir hata oluştu. Lütfen daha sonra tekrar deneyin.',
										});
									});
								});
						},
					},
				],
			});
		},
	},
	watch: {
		'settings.company': {
			handler(value) {
				const company = this.$cloneDeep(value);
				this.cmsSiteSettings = {
					isRedirectCustomerSite: company.cmpIsRedirectCustomerSite,
					redirectSiteUrl: company.cmpRedirectSiteUrl,
					hasListingCount: company.cmpHasListingCount,
					contactFormEMail: company.cmpContactFormEMail,
					isExpertiseFileMandatory: company.cmpIsExpertiseFileMandatory,
					showListingPlate: company.cmpShowListingPlate,
					showListingCarMenu: company.cmpShowListingCarMenu,
					isLocation: company.cmpIsLocation,
					hasUI: company.cmpHasUI,
					isGivePrice: company.cmpIsGivePrice,
					hasThumbImgZoom: company.cmpHasThumbImgZoom,
					scheduleType: company.cmpScheduleType,
				};
			},
			deep: true,
		},
	},
};
</script>

<style></style>
