/* eslint-disable no-unused-expressions */
import { requiredIf, url, email } from 'vuelidate/lib/validators';

const siteSettingValidationMixin = {
	validations: {
		cmsSiteSettings: {
			redirectSiteUrl: {
				required: requiredIf((x) => x.isRedirectCustomerSite),
				url,
			},
			contactFormEMail: {
				email,
			},
		},
	},
	methods: {
		// validation methods for this page
		redirectSiteUrlErrors() {
			const errors = [];
			const { $dirty, required, url } = this.$v.cmsSiteSettings.redirectSiteUrl;
			if (!$dirty) return errors;
			!required && errors.push('Lütfen bir web adres giriniz.');
			!url && errors.push('Geçerli bir web adresi giriniz. Örnek: https://www.example.com');
			return errors;
		},
		contactFormEMail() {
			const errors = [];
			const { $dirty, email } = this.$v.cmsSiteSettings.contactFormEMail;
			if (!$dirty) return errors;
			!email && errors.push('Lütfen geçerli bir email adres giriniz.');
			return errors;
		},
	},
};

export default siteSettingValidationMixin;
